<template>
  <section>
      <div class="content-header">
        <h2>Works cited</h2>
      </div>
      <div class="content-wrapper">
        <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared.  </p>
        <ul class="pb-3">
          <li class="pb-3">Rebello, C. (2021, May 7). <em>Academic resilience</em> [Video]. MediaSpace. <a href="https://mediaspace.uab.edu/media/Academic+Resilience/1_9oatwabk" target="_blank">mediaspace.uab.edu/media/Academic+Resilience/1_9oatwabk</a></li>
          <li class="pb-3">Kaufman, S. B. (2014, April 14). Why Academic Tenacity Matters. Scientific American. <a href="https://blogs.scientificamerican.com/beautiful-minds/why-academic-tenacity-matters/" target="_blank">blogs.scientificamerican.com/beautiful-minds/why-academic-tenacity-matters/</a></li>
          <li class="pb-3">Academic resilience during COVID, The President and Fellows of Harvard College. (n.d.). Applying the science of resilience to the COVID-19 crisis: Q&A with Dr. Karmel Choi and Dr. Kristen Nishimi. <em>Harvard T. H. Chan School of Public Health</em>.</li>
          <li><em>Academic Resilience in a Post-COVID World</em>. (2021, October 5). EdCan Network. <a href="https://www.edcan.ca/articles/academic-resilience-in-a-post-covid-world/" target="_blank">https://www.edcan.ca/articles/academic-resilience-in-a-post-covid-world/</a></li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '06',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
